.project_header {
  padding: 1rem;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1;
  background: white;
  .flex {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  @media screen and (max-width: 799px) {
    top: 4.25rem;
  }
  h2 {
    margin-right: 1rem;
    margin-bottom: 0.5rem;
  }
  a, .disabled {
    width: fit-content;
    color: white;
    text-decoration: none;
    background: var(--dark);
    margin-right: 1rem;
    margin-bottom: 0.5rem;
    padding: 0.3rem 0.5rem;
    border: 1px solid var(--dark-darker);
    border-radius: 3px;
    display: flex;
    align-items: center;
    svg {
      margin-right: 0.3rem;
    }
    &:hover,
    &:active {
      opacity: 0.9;
    }
  }
  .disabled {
    background: var(--shadow-bis);
    border: 1px solid var(--shadow-ter);
  }
}

.project_grid {
  width: 100%;
  padding: 0 0 2rem 0;
  line-height: 1.5rem;

  display: grid;
  grid-template-columns: repeat(3, minmax(5rem, 25rem));
  grid-template-rows: auto;
  @media screen and (max-width: 799px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
  }
}

.tags {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  font-size: 80%;
  grid-column: 1/4;
  @media screen and (max-width: 799px) {
    grid-column: 1/4;
    font-size: 60%;
  }
}

.large_photo {
  width: fit-content;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    border: 3px solid black;
    width: 100%;
  }
  grid-column: span 2;
  @media screen and (max-width: 799px) {
    grid-column: 1/4;
  }
}

.small_photo {
  width: 80%;
  margin: auto;
  padding: 1rem;
  display: flex;
  img {
    border: 3px solid black;
    width: 100%;
  }
  @media screen and (max-width: 799px) {
    grid-column: 1/4;
    max-width: 20rem;
    margin: 0 auto;
  }
}

.tablet_photo {
  width: 100%;
  display: flex;
  padding: 1rem;
  img {
    border: 3px solid black;
    width: 100%;
  }
  grid-column: span 1;
  @media screen and (max-width: 799px) {
    grid-column: 1/4;
    width: 100%;
    margin: 0 auto;
  }
}

.project_text {
  width: 100%;
  margin: 2rem 0;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: justify;
  grid-column: span 3;
  @media screen and (max-width: 799px) {
    grid-column: 1/4;
  }
  li {
    margin-left: 1rem;
  }
}
