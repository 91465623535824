.language {
  width: fit-content;
  padding: 0.3rem 1rem;
  margin: 0.5rem;
  border-radius: 3px;
  font-weight: bold;
  color: white;
  display: flex;
  align-items: center;
  > *:first-child {
    margin-right: 0.3rem;
  }
}

.language_tags {
  display: flex;
  flex-wrap: wrap;
}

.React\.js {
  background: #4d908e;
}

.Redux {
  background: #577590;
}

.JS {
  background: #f9c74f;
}

.Rails {
  background: #f94144;
}

.HTML {
  background: #f3722c;
}

.CSS {
  background: #277da1;
}

.Node\.js {
  background: #90be6d;
}
