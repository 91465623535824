.presentationGrid {
  width: 100%;
  padding: 2rem 0;
  line-height: 1.5rem;
  > div {
    padding: 1rem;
  }
  h3 {
    display: flex;
    align-items: center;
    padding: 1rem;
  }

  display: grid;
  grid-template-columns: repeat(4, minmax(5rem, 20rem));
  grid-template-rows: repeat(3, minmax(auto, auto));
  @media screen and (max-width: 799px) {
    grid-template-columns: minmax(100vw, 1fr);
    grid-template-rows: repeat(5, auto);
  }
}

.photo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;

  img {
    width: 100%;
    max-width: 15rem;
    border-radius: 1rem;
  }

  grid-column: 1;
  grid-row: 1;
  @media screen and (max-width: 799px) {
    grid-column: 1;
    grid-row: 1;
  }
}

.card {
  background: var(--shadow);
  border: 1px solid hsl(214, 23%, 56%, 0.2);
  border-radius: 3px;
  margin: 2rem 2rem 0 2rem;
  h3 svg {
    margin-right: 0.5rem;
  }
}

.presentationText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: justify;

  grid-column: 2/5;
  grid-row: 1;
  @media screen and (max-width: 799px) {
    grid-column: 1;
    grid-row: 2;
  }

  h2 {
    margin-bottom: 0.5rem;
  }
  h3 {
    color: var(--light);
    margin-bottom: 1rem;
    padding: unset;
  }
}

.languages {
  grid-column: 1/3;
  grid-row: 2;
  @media screen and (max-width: 799px) {
    grid-column: 1;
    grid-row: 3;
  }
}

.skills {
  ul {
    list-style: none;
  }

  grid-column: 3/5;
  grid-row: 2;
  @media screen and (max-width: 799px) {
    grid-column: 1;
    grid-row: 4;
  }
}

.pathway {
  ul {
    margin-left: 1rem;
  }

  grid-column: 1/3;
  grid-row: 3;
  @media screen and (max-width: 799px) {
    grid-column: 1;
    grid-row: 5;
  }
}
